import React from 'react';
import { Link } from 'gatsby';
import { format } from 'date-fns';

import SEO from '../components/_atoms/SEO/SEO';

import { hexToRgb } from '../utils';

import Layout from '../layouts';

import './home.scss';

const Card = ({ data, uid, i }) => (
    <Link
        className="post"
        to={`/${uid}`}
    >
        <div className="color-layer" style={data.color && {backgroundColor: `rgba(${hexToRgb(data.color)}, 0.7)`}}>
            <div className="meta">
                <p className="date">{format(new Date(data.date), 'Do MMM YYYY')}</p>
                <p className="type">{data.post_type}</p>
            </div>
            <h3 className="title">{data.title.text}</h3>
            { data.body && (data.body[0].items[0].text.text[0] === '"'
                && data.body[0].items[0].text.text[data.body[0].items[0].text.text.length-1] === '"')
                ? <p className="preview">{data.body[0].items[1].text.text.replace(/<[^>]*>/g, '').split(' ').splice(0, i === 0 ? 60 : 27).join(' ') + '...'}</p>
                : <p className="preview">{data.body[0].items[0].text.text.replace(/<[^>]*>/g, '').split(' ').splice(0, i === 0 ? 60 : 27).join(' ') + '...'}</p> }
        </div>
    </Link>
);

const Home = ({ pageContext: { posts } }) => {
    return (
        <Layout>
            <SEO />
            <div className="wrapper">
                <div className="banner">
                    <h1 className="heading">Blog</h1>
                    <p className="subheading">The latest updates, community guides, and educational information to improve yourself as a writer.</p>
                </div>
                <div className="content">
                    <div className="posts">
                        {posts.sort((a, b) => new Date(b.node.data.date) - new Date(a.node.data.date)).map(({ node: { data, uid } }, index) => (
                            <Card data={data} uid={uid} key={index} i={index} />
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Home;
